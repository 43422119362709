import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/myiconfont/iconfont.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { IsPC } from "@/assets/js/isPC";
import { px2rem } from "@/assets/js/px2rem";

import axios from "axios";

import NewPagination from "./components/NewPagination";

// let url = "https://titaniumapi.pzhfxh.com:8889";
let url = "https://wuyuekeji888.com:8890";
//let url = "http://192.168.101.28:8889";
Vue.prototype.baseUrl = url;
const service = axios.create({
  baseURL: url, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests，跨域请求时发送Cookie
  timeout: 8000, // request timeout ,请求超时时间
});

Vue.prototype.$axios = service;
Vue.use(ElementUI);
//注册分页组件，二次封装了element的分页
Vue.component("NewPagination", NewPagination);
Vue.config.productionTip = false;

Vue.prototype.phone = "18881214276";

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");

// 缩放比例
let proportion;

// 判断设备类型
if (IsPC()) {
  proportion = 1920;
  router.push({ path: "/Home" });
} else {
  proportion = 375;
  router.push({ path: "/HomeMobile" });
}

// 自适应
window.onload = () => px2rem(proportion);
window.onresize = () => px2rem(proportion);
