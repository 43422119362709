import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    adlist: [],
    languageType: "CHINESE",
    staticName: {},
  },
  mutations: {
    SET_ADLIST: (state, adlist) => {
      state.adlist = adlist;
    },
    SET_LANGUAGE: (state, languageType) => {
      state.languageType = languageType;
    },
    SET_STATICNAME: (state, staticName) => {
      state.staticName = staticName;
    },
  },
  actions: {
    setAdList({ commit, state }, data) {
      commit("SET_ADLIST", data);
    },
    setLanguageType({ commit, state }, data) {
      commit("SET_LANGUAGE", data);
    },
    setStaticName({ commit, state }, data) {
      commit("SET_STATICNAME", data);
    },
  },
  getters: {
    getAdList: (state) => {
      return state.adlist;
    },
    languageType: (state) => {
      return state.languageType;
    },
    staticName: (state) => {
      return state.staticName;
    },
  },
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
      storage: window.sessionStorage,
      // 存储的 key 的key值
      key: "store",
      render(state) {
        // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
        return { ...state };
      },
    }),
  ],
});
