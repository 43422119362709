import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Router',
        component: function () {
            return import( '../views/Router')
        }
    },
    {
        path: '/Home',
        name: 'Home',
        component: function () {
            return import( '../views/Home')
        }
    },
    {
        path: '/corecustomiza',
        name: 'Corecustomiza',
        component: function () {
            return import( '../views/Corecustomiza')
        }
    },
    {
        path: '/titaniumproducts/:id',
        name: 'TitaniumProducts',
        component: function () {
            return import( '../views/TitaniumProducts')
        }
    },
    {
        path: '/newdetails/:newType/:newId',
        name: 'NewDetails',
        component: function () {
            return import( '../views/NewDetails')
        }
    },
    {
        path: '/newlist/:newType',
        name: 'NewList',
        component: function () {
            return import( '../views/NewList')
        }
    },
    {
        path: '/product',
        name: 'Product',
        component: function () {
            return import( '../views/Product')
        }
    },
    {
        path: '/wuyue',
        name: 'Wuyue',
        component: function () {
            return import( '../views/GoWuYue')
        }
    },
    {
        path: '/contactus',
        name: 'ContactUs',
        component: function () {
            return import( '../views/ContactUs')
        },
    },
    {
        path: '/innovate',
        name: 'Innovate',
        component: function () {
            return import( '../views/innovate')
        }
    },
    {
        path: '/quality',
        name: 'Quality',
        component: function () {
            return import( '../views/quality')
        }
    },
    {
        path: '/HomeMobile',
        name: 'HomeMobile',
        component: function () {
            return import( '../views/mobile/HomeMobile')

        }
    },
    {
        path: '/NewListMobile/:newType',
        name: 'NewListMobile',
        component: function () {
            return import( '../views/mobile/NewListMobile')
        }
    },
    {
        path: '/newDetailsMobile/:newType/:newId',
        name: 'NewDetailsMobile',
        component: function () {
            return import( '../views/mobile/NewDetailsMobile')
        }
    },
    {
        path: '/innovateMobile',
        name: 'innovateMobile',
        component: function () {
            return import( '../views/mobile/innovateMobile')
        }
    },
    {
        path: '/CorecustomizaMobile',
        name: 'CorecustomizaMobile',
        component: function () {
            return import( '../views/mobile/CorecustomizaMobile')

        }
    },
    {
        path: '/TitaniumProductsMobile/:id',
        name: 'TitaniumProductsMobile',
        component: function () {
            return import( '../views/mobile/TitaniumProductsMobile')

        }
    },
    {
        path: '/qualityMobile',
        name: 'QualityMobile',
        component: function () {
            return import( '../views/mobile/qualityMobile')
        }
    },
    {
        path: '/GoWuYueMobile',
        name: 'GoWuYueMobile',
        component: function () {
            return import( '../views/mobile/GoWuYueMobile')
        }
    },
    {
        path: '/ContactUsMobile',
        name: 'ContactUsMobile',
        component: function () {
            return import( '../views/mobile/ContactUsMobile')
        }
    },
    {
        path: '/ProductMobile',
        name: 'ProductMobile',
        component: function () {
            return import( '../views/mobile/ProductMobile')
        }
    },

]

const router = new VueRouter({
    routes
})

//以下代码解决路由地址重复点击的报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


export default router
