<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { IsPC } from "@/assets/js/isPC";
import router from "@/router";

export default {
  name: "App",
  watch: {
    $route() {
      // let routerPath = this.$route.fullPath;

      // if (IsPC()&& routerPath.indexOf("=")!=-1){
      //
      //
      //  let setT = setTimeout(function (){
      //    let doc = document.getElementsByClassName('head')[0].offsetHeight;

      //     document.documentElement.scrollTop = doc-50;
      //     clearTimeout(setT)
      //   }, 50)
      //
      // }
      document.documentElement.scrollTop = 0;
    },
  },
  created() {
    // this.getData()
    if (localStorage.getItem("path")) {
      let s = setTimeout(() => {
        // 缩放比例
        let proportion;

        if (localStorage.getItem("path") === "/") {
          // 判断设备类型
          if (IsPC()) {
            proportion = 1920;
            router.push({ path: "/Home" });
          } else {
            proportion = 375;
            router.push({ path: "/HomeMobile" });
          }
        } else {
          this.$router.push({ path: localStorage.getItem("path") });
          localStorage.removeItem("path");
        }
        clearTimeout(s);
      }, 50);
    }
    // // 在页面加载时读取sessionStorage里的状态信息
    // if (localStorage.getItem('store')) {
    //   this.$store.replaceState(
    //       Object.assign(
    //           {},
    //           this.$store.state,
    //           JSON.parse(localStorage.getItem('store'))
    //       )
    //   );
    //   localStorage.removeItem('store');

    // }

    // // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // // beforeunload事件在页面刷新时先触发
    // window.addEventListener('beforeunload', () => {

    //     this.$store.dispatch('setLanguageType', this.$store.state.languageType || 'CHINESE')
    //     localStorage.setItem('path', this.$route.fullPath)
    //     localStorage.setItem('store', JSON.stringify(this.$store.state))

    // })
  },
  methods: {
    // getData() {
    //   this.$axios({
    //     url: "/queryAdvertsByAll",
    //     method: 'get'
    //   }).then(res => {
    //     this.$store.dispatch('setAdList', res.data.value);
    //   })
    // },
  },
};
</script>
<style lang="scss">
@import "./src/assets/scss/main";
</style>
