export function px2rem(proportion) {
    const width = window.document.documentElement.getBoundingClientRect().width

    // 将页面比例控制在一个合理的值
    let rem = width / proportion
    // if (width < 1000) {
    //     rem = width / 1920 * 1.5
    // } else if (width > 1000 && width < 2000) {
    //     rem = width / 1920
    // }
    document.getElementsByTagName('html')[0].style.fontSize = rem + 'px'
}

export default {
    px2rem
}